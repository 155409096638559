<template>
  <NavBar :title="'核销员管理'"></NavBar>
  <div class="content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        class="items"
        @load="queryList"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <img
            class="avatar"
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_104414_62a6a47e42202.png"
            alt="成员"
          />
          <div class="info">
            <p>{{ item.nickname }}</p>
            <p>手机号：{{ item.phone }}</p>
            <p>核销时间：{{ item.update_time }}</p>
          </div>
          <img
            class="del"
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220613_140529_62a6d3a9c341e.png"
            alt="删除"
            @click="del(item)"
          />
        </div>
      </van-list>
    </van-pull-refresh>
    <button class="add" @click="showAdd = true">新增核销员</button>
  </div>

  <div style="text-align: center">
    <van-popup
      v-model:show="showAdd"
      position="bottom"
      closeable
      close-icon-position="top-left"
      teleport="body"
      :style="{ height: '100%' }"
    >
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="fromData.nickname"
            name="username"
            label="姓名"
            placeholder="姓名"
          />
          <van-field
            v-model="fromData.phone"
            name="password"
            label="手机号"
            placeholder="手机号"
          />

          <van-field disabled />
        </van-cell-group>
        <div style="text-align: center">
          <button class="add" type="submit">提交</button>
        </div>
      </van-form></van-popup
    >
  </div>
</template>
<script>
import {
  List,
  Cell,
  PullRefresh,
  Popup,
  Form,
  Field,
  CellGroup,
  Dialog,
} from 'vant'
import moment from 'moment'
export default {
  name: 'Verification',
  components: {
    [Cell.name]: Cell,
    [List.name]: List,
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Dialog.Component.name]: Dialog.Component,
  },
  created() {
    moment.locale('zh-cn')
  },
  data() {
    return {
      list: [],
      showAdd: false,
      finished: false,
      loading: false,
      refreshing: false,
      queryParams: {
        page: 1,
        limit: 10,
      },
      fromData: {
        phone: '',
        nickname: '',
        activity: '',
      },
    }
  },
  methods: {
    del(item) {
      Dialog.confirm({
        message: '确定删除该核销员吗?',
      })
        .then(() => {
          this.$http({
            url: '/merchant/destroyDelete',
            method: 'post',
            data: this.$http.adornData({ id: item.id }),
          }).then(() => {
            this.$notify({ type: 'success', message: '删除成功' })
            this.queryList()
          })
        })
        .catch(() => {})
    },
    onSubmit() {
      if (this.fromData.nickname === '') {
        this.$notify('请填写正确姓名!')
        return
      } else if (this.fromData.phone === '') {
        this.$notify('请填写正确手机号!')
        return
      } else if (this.$state.activity.id) {
        this.$notify('请重新进入.')
      }
      this.fromData.activity = this.$state.activity.id
      this.$http({
        url: '/merchant/destroyCreate',
        method: 'post',
        data: this.$http.adornData(this.fromData),
      }).then(() => {
        this.showAdd = false
        this.$notify({ type: 'success', message: '添加成功' })
        this.queryList()
      })
      // /merchant/destroyCreate
    },
    fomattTime(time) {
      return moment(time * 1000).format('LL')
    },
    onRefresh() {
      this.queryParams.page = 1
      this.refreshing = true
      this.queryList()
    },
    queryList() {
      this.loading = true
      this.finished = false
      this.$http({
        url: '/merchant/destroyIndex',
        method: 'get',
        data: this.$http.adornParams(this.queryParams),
      })
        .then(({ data }) => {
          this.list = data.data
          this.loading = false
          if (data.last_page > this.queryParams.page) {
            this.page++
          } else {
            this.refreshing = false
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.van-cell-group--inset) {
  padding-top: 40px;
}
:deep(.van-cell:after) {
  border-bottom: 1px solid #d9d9d9;
}

.add {
  margin: 20px auto;
  text-align: center;
  width: 311px;
  height: 44px;
  background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
  border-radius: 8px 8px 8px 8px;
  border: none;
  color: #fff;
}
.content {
  height: 100vh;
  padding: 16px 32px;
  background: #f9f9f9;
  .item {
    height: 84px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    display: flex;
    margin: 10px 0;
    .avatar {
      width: 50px;
      height: 50px;
      object-fit: contain;
      padding: 15px;
    }
    .info {
      height: 50px;
      overflow: hidden;
      padding: 15px 0;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
      line-height: 16px;
    }
    .del {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }
}
</style>
